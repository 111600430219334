<template>
	<div class="opinion">
		<div class="service-right-wrap n">
			<div class="title title-vertical flex-space-between">
				<span>意见反馈</span>
			</div>
			<div class="service-right-content flex-direction-column">
				<div class="notice">
					亲爱的用户，您好：<br/>
					欢迎您给我们提出使用中遇到的问题或建议，请在下面填写并留下您的联系方式，感谢您对我们的支持！
				</div>
				<!-- 联系方式 -->
				<div class="item flex">
					<div class="item-label">姓名：</div>
					<input v-model="params.name" placeholder="请输入姓名"/>
					<div class="item-right xing">必填</div>
				</div>
				<!-- 联系方式 -->
				<div class="item flex">
					<div class="item-label">联系方式：</div>
					<input
						v-model="params.contact"
						placeholder="请填写手机号/QQ号方便与您联系，我们会对您的联系方式保密，请放心填写~"
					/>
					<div class="item-right xing">必填</div>
				</div>
				<!-- 身份证号 -->
				<div class="item flex">
					<div class="item-label">身份证号：</div>
					<input v-model="params.id_card" placeholder="请输入身份证号"/>
					<div class="item-right xing">必填</div>
				</div>
				<!-- 反馈类型 -->
				<div class="item flex">
					<div class="item-label">反馈类型：</div>
					<div class="item-center">
						<el-dropdown :hide-on-click="false" @command="handleCommand">
              <span class="el-dropdown-link">
                {{ params.type ? params.type : '请选择反馈类型' }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
							<el-dropdown-menu slot="dropdown" class="dropdown-menu">
								<el-dropdown-item :command="item.name" v-for="item in type" :key="item.id">{{
										item.name
									}}
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
					<div class="item-right xing">必填</div>
				</div>
				<!-- 反馈内容 -->
				<div class="item flex">
					<div class="item-label">反馈内容：</div>
					<textarea v-model="params.content" placeholder="请输入反馈内容"></textarea>
					<div class="item-right xing">必填</div>
				</div>

				<!-- 提交反馈 -->
				<div class="btn flex" @click="submitClick">
					<span>提交反馈</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {EventBus} from '@/utils/event-bus'
import {isObjEmpty, phoneReg} from '@/utils/tools'
import {postFeedbackDo, postServeDetail, postTitle} from '@/service/api/index'

export default {
	name: 'TianxieOpinion',

	data() {
		return {
			type: [],
			params: {
				name: '',
				type: '',
				id_card: '',
				content: '',
				contact: ''
			}
		}
	},

	mounted() {
		this.postServeDetail()
		this.postTitle()
	},

	methods: {
		postTitle() {
			postTitle({
				id: 2,
				type: 1
			}).then((res) => {
				// console.log(res)
				EventBus.$emit('pathArr', res.msg)
			})
		},
		postServeDetail() {
			postServeDetail({id: 2}).then((res) => {
				// console.log(res)
				this.type = Object.freeze(res.msg)
			})
		},
		postFeedbackDo() {
			postFeedbackDo(this.params).then((res) => {
				if (res.code == 1) {
					this.$message.success(res.msg)
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		handleCommand(e) {
			this.params.type = e
		},
		submitClick() {
			if (isObjEmpty(this.params)) {
				this.$message.warning('请填写表单内容')
			} else {
				phoneReg(this.params.contact)
					.then(() => {
						this.postFeedbackDo()
					})
					.catch((err) => {
						this.$message.error(err)
					})
			}
		}
	}
}
</script>

<style>
.dropdown-menu {
	width: 830px !important;
}
</style>
<style lang="less" scoped>
.notice {
	line-height: 25px;
	color: #999999;
	height: 47px;
	font-size: 16px;
	line-height: 25px;
	margin-top: 21px;
}

.item {
	margin-top: 31px;
	align-items: flex-start;

	.item-label {
		height: 50px;
		line-height: 50px;
		font-size: 16px;
		width: 93px;
	}

	textarea {
		min-width: 802px;
		max-width: 802px;
		min-height: 230px;
		max-height: 230px;
		border: 1px solid #dddddd;
		border-radius: 3px;
		padding: 14px 20px;
		outline: none;
		font-size: 16px;
	}

	input {
		padding: 14px 20px;
		border: 1px solid #dddddd;
		outline: none;
		font-size: 16px;
		width: 347px;
	}

	.item-center {
		width: 840px;
		// width: 347px;
		height: 50px;
		border: 1px solid #dddddd;
		border-radius: 3px;

		/deep/ .el-dropdown {
			width: 100%;
			height: 100%;
		}

		/deep/ .el-dropdown-link {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 100%;
			font-size: 16px;
			padding: 0 20px;
		}
	}

	.item-right {
		margin-left: 20px;
		width: 45px;
		height: 50px;
		line-height: 50px;
		font-size: 16px;
	}
}

.xing {
	&::before {
		content: '*';
		color: #e50114;
		font-size: 16px;
		margin-right: 2px;
	}
}

.btn {
	justify-content: flex-end;
	margin-top: 30px;
	cursor: pointer;

	span {
		padding: 13px 45px 12px 46px;
		background: #e50114;
		color: #ffffff;
		font-size: 18px;
		border-radius: 3px;
	}
}
</style>
